<template>
  <el-container class="shopList ">
    <el-header class="box add">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>企业管理</el-breadcrumb-item>
        <el-breadcrumb-item>编辑企业</el-breadcrumb-item>
      </el-breadcrumb>
       <div>
        <el-button class="back_btn" size="medium" plain @click="$router.back()">返回</el-button>
      </div>
    </el-header>
    <el-main class="p15 pt15">
      <el-form :inline="true" status-icon ref="form" :model="form" class="demo-ruleForm">
        <el-row class="box mt20  f14">
          <el-col :span="11" class="tl">
            <el-form-item label="监管类型：" size="medium" prop="company_type_id" class="w" label-width="28%"
              :rules="[{required: true,message: '请选择监管类型',trigger: 'blur'}]">
              <el-select v-model="form.company_type_id" filterable placeholder="请选择监管类型"
                @change="getTag(form.company_type_id,1)">
                <el-option v-for="item in typetOptions" :key="item.company_type_id" :label="item.company_type_name"
                  :value="String(item.company_type_id)"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item label="主体类型：" size="medium" prop="company_tag_id" class="w" label-width="28%"
              :rules="[{required: true,message: '请选择主体类型',trigger: 'blur'}]">
              <el-select v-model="form.company_tag_id" filterable placeholder="请选择主体类型" @change="getDictionary(form.company_tag_id,1)">
                <el-option v-for="item in tagOptions" :key="item.company_tag_id" :label="item.company_tag_name"
                  :value="String(item.company_tag_id)"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="统一社会信用代码：" size="medium" prop="credit_code"
              :rules="[{required: true, message: '请输入统一社会信用代码', trigger: 'blur' },{ min: 18,max: 18, message: '请如实填写18位统一社会信用代码，以供核对', trigger: 'blur' }]">
              <el-input placeholder="请输入统一社会信用代码"  clearable 
                v-model="form.credit_code" class="input-with-select">
                 <el-button slot="append"  @click="companyCheck">验证企业</el-button>
              </el-input>
            </el-form-item>
          </el-col>
           <!-- <el-col :span="11" class="tl" :offset="1">
            <el-form-item class="w" label-width="28%"  size="medium" >
             <el-button   class="ml_5 mb5"  size="medium" type="primary" @click="companyCheck">验证企业</el-button>
            </el-form-item>
          </el-col> -->
          <el-col v-if="form.company_tag_id!=1&&form.company_tag_id!=2&&form.company_tag_id!=4" :span="11" class="tl">
            <el-form-item label="企业类型：" size="medium" prop="company_type" class="w" label-width="28%">
              <el-select v-model="form.company_type" filterable placeholder="请选择企业类型">
                <el-option v-for="item in typeCompany" :key="item.dictionary_field" :label="item.dictionary_field"
                  :value="item.dictionary_field"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item label="企业名称：" class="w" label-width="28%" prop='company_name'
              :rules="[{required: true,message: '请输入企业名称', trigger: 'blur' },{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '不允许输入空格等特殊符号' }]">
              <el-input v-model="form.company_name" size="medium" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22" class="tl">
            <el-row class="box  f14">
              <el-form-item class="w" label-width="14%" label="经营地址：" size="medium" prop="vill"
                :rules="[{required: true,message: '请选择到村级', trigger: ['blur','change']}]">
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable v-model="provinceTemp" placeholder="请选择省级" @change="handleChange($event,2)"
                      value-key="region_name" :disabled="provinceFlag">
                      <el-option v-for="item in provinceOptions" :key="item.region_code" :label="item.region_name"
                        :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable v-model="cityTemp" placeholder="请选择市级" @change="handleChange($event,3)"
                      value-key="region_name" :disabled="cityFlag">
                      <el-option v-for="item in cityOptions" :key="item.region_code" :label="item.region_name"
                        :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable v-model="areaTemp" placeholder="请选择区/县" @change="handleChange($event,4)"
                      value-key="region_name" :disabled="areaFlag">
                      <el-option v-for="item in areaOptions" :key="item.region_code" :label="item.region_name"
                        :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable v-model="townTemp" placeholder="请选择街道/镇" @change="handleChange($event,5)"
                      value-key="region_name" :disabled="townFlag">
                      <el-option v-for="item in townOptions" :key="item.region_code" :label="item.region_name"
                        :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable v-model="villTemp" placeholder="请选择居委会/村" value-key="region_name"
                      @change="handleChange($event,6)" :disabled="villFlag">
                      <el-option v-for="item in villOptions" :key="item.region_code" :label="item.region_name"
                        :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-row>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item label="详细地址：" class="w" label-width="28%">
              <el-input placeholder="如道路、门牌号、小区、楼栋号、单元室等（请勿重复录入省市区县信息）" v-model="form.address" size="medium" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item label="营业状态：" class="w" label-width="28%" size="medium" prop="business_state"
              :rules="[{required: true,message: '请选择营业状态',trigger: 'blur'}]">
              <el-select v-model="form.business_state" filterable placeholder="请选择营业状态">
                <el-option v-for="item in Options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="22" class="tl">
            <el-form-item label="企业门店照片：" class="w" label-width="14%">
              <div class="recordadd-upload">
                <el-upload v-if="imageUrl=='1234567'" ref="upload" multiple action="/pc/file/upload" :file-list="fileList"
                  accept="image/png,image/jpg,image/jpeg" list-type="picture-card" :on-success="handlepoll"
                  :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :before-remove="beforeRemove">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-upload v-else ref="upload" multiple action="" :file-list="fileList"
                  accept="image/png,image/jpg,image/jpeg" list-type="picture-card" :http-request="(params) =>{return handlepoll(params)}"
                  :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :before-remove="beforeRemove">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="22" class="tl">
            <el-button class="ml_5" size="medium" type="primary" @click="submitForm('form')">提交</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-main>
  </el-container>
</template>
<script>
import axios from '../../../util/api'
import { local, aa,session } from '../../../util/util'
import { imageUrl } from '../../../config/index'
import { tengxuncloud } from '../../../util/aliOss'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      imageUrl: imageUrl,
      morenUrl: require('../../../assets/image/moren.png'),
      company_type_id:'',
      form: {
        company_id: '',
        company_type_id: '',
        company_tag_id: '',
        company_type: '',
        credit_code:'',
        province: "",
        city: "",
        area: "",
        town: "",
        vill: "",
        address: "",
        business_state: "1",
        company_logo_url: ''
      },
      user: '',
      company: {},
      typetOptions: [],
      tagOptions: [],
      typeCompany: [],
      provinceTemp: "",
      cityTemp: "",
      areaTemp: "",
      townTemp: "",
      villTemp: "",
      provinceFlag: false,
      cityFlag: false,
      areaFlag: false,
      townFlag: false,
      villFlag: false,
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
      townOptions: [],
      villOptions: [],
      fileList: [],
      userData: {},
      Options: [{ label: "正常营业", value: "1" },
      { label: "暂停营业", value: "2" },
      { label: "停止营业", value: "3" }],
      dialogImageUrl: '',
      dialogVisible: false,
      checkCompany:true,
    }
  },
  created () {
    var that = this;
    that.form.company_id = String(session.get('params').id);

    //   this.getdata()
  },
  async mounted () {
    this.userData = {};
    await this.getdata({ company_id: this.form.company_id });
  },
  methods: {
    getdata (params) {
      var that = this;
      axios.get('/pc/company/single', params).then((v) => {
        that.company = v.data.company;
        that.form = v.data.company;
        this.userData = {
          user_province: that.company.province,
          user_city: that.company.city,
          user_area: that.company.area,
          user_town: that.company.town,
          user_vill: that.company.vill,
        }
        that.company_type_id = String(v.data.company.company_type_id);
        that.form.company_type_id = String(v.data.company.company_type_id);
        that.form.company_tag_id = String(v.data.company.company_tag_id);
        that.form.company_id = String(v.data.company.company_id);
        this.fileList = [{ name: '', url: v.data.company.company_logo_url ? (v.data.company.company_logo_url) : that.morenUrl }];
        this.getType()
        this.handleUserLevel(this.userData, that.company);

      })
    },
    getType () {
      var that = this;
      axios.get('/pc/company-type/all').then((v) => {
        that.typetOptions = v.data.company_type_list;
        this.getTag(that.form.company_type_id,2)
      })
    },
    getTag (params,index) {
      var that = this;
      axios.get('/pc/company-tag/all', { company_type_id: params }).then((v) => {
        that.tagOptions = v.data.company_tag_list;
         if(index==1){
             that.form.company_tag_id=String(that.tagOptions[0].company_tag_id)
             this.form.company_type=''
             that.getDictionary(that.form.company_tag_id,1)
         }else{
           that.form.company_tag_id = String(that.form.company_tag_id)
           that.getDictionary(that.form.company_tag_id,2)
         }
        
        
      })
    },
    getDictionary (params,index) {
      var that = this;
      axios.get('/pc/dictionary/all', { company_tag_id: params }).then((v) => {
        that.typeCompany =v.data.dictionary_list.企业类型?v.data.dictionary_list.企业类型.企业类型:[];
      })
    },
    companyCheck(){
      if(this.form.credit_code==''){
        this.$message.error("请输入统一社会信用代码");
            return false;
      };
      axios.get('/pc/company/check', { credit_code: this.form.credit_code }).then((v) => {
        this.form.company_name=v.data.companyName;
        
      })
    },
    async handleUserLevel (userData, company) {
        this.provinceOptions = await this.fetchRegionData(1);
        let province = this.provinceOptions.find(
          item =>
            item.region_name ==
            (company.province ? userData.user_province : company.province)
        );
        this.provinceTemp = province;
        if (parseInt(userData.user_type) >= 1 || company.city) {
          this.cityOptions = await this.fetchRegionData(
            2,
            province.region_code
          );
          let city = this.cityOptions.find(
            item =>
              item.region_name ==
              (userData.user_city ? userData.user_city : company.city)
          );
          if (parseInt(userData.user_type) >= 1) this.provinceFlag = true;
          if (parseInt(userData.user_type) >= 2 || company.area) {
            this.areaOptions = await this.fetchRegionData(3, city.region_code);
            let area = this.areaOptions.find(
              item =>
                item.region_name ==
                (userData.user_area ? userData.user_area : company.area)
            );
            this.cityTemp = city;
            if (parseInt(userData.user_type) >= 2) this.cityFlag = true;
            if (parseInt(userData.user_type) >= 3 || company.town) {

              this.townOptions = await this.fetchRegionData(4, area.region_code);
              let town = this.townOptions.find(
                item => item.region_name == (userData.user_town ? userData.user_town : company.town)
              );

              this.areaTemp = area;
              if (parseInt(userData.user_type) >= 3) this.areaFlag = true;
              if (parseInt(userData.user_type) >= 4 || company.vill) {


                this.villOptions = await this.fetchRegionData(
                  5,
                  town.region_code
                );
                let vill = this.villOptions.find(
                  item =>
                    item.region_name ==
                    (userData.user_vill ? userData.user_vill : company.vill)
                );
                this.townTemp = town;

                if (parseInt(userData.user_type) >= 4) this.townFlag = true;
                if (parseInt(userData.user_type) >= 5 || company.vill) {
                  this.villTemp = vill;
                  if (parseInt(userData.user_type) >= 5) this.villFlag = true;
                }
              }
            }
          }
        }
    },
    fetchRegionData (type, region_high_code) {
      let region_type = type.toString();
      return new Promise((resolve, reject) => {
        axios
          .get("/pc/region/all", { region_type, region_high_code })
          .then(response => {
            resolve(response.data.region_list);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    async handleChange (item, type) {
      let regionData = await this.fetchRegionData(type, item.region_code);
      switch (type) {
        case 2:
          this.cityOptions = regionData;
          this.areaOptions = [];
          this.townOptions = [];
          this.villOptions = [];
          this.cityTemp = "";
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 3:
          this.areaOptions = regionData;
          this.townOptions = [];
          this.villOptions = [];
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 4:
          this.townOptions = regionData;
          this.villOptions = [];
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 5:
          this.villOptions = regionData;
          this.form.town = item.region_name;
          this.villTemp = "";
          break;
        case 6:
          this.form.vill = item.region_name;
          break;
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handlepoll (response, file, fileList) {
      if(this.imageUrl=='1234567'){
        this.form.company_logo_url = response.data.visit_url;
      }else{
        tengxuncloud(response).then((res) => {
        this.form.company_logo_url =res.url
       })
      }
    },
    handleRemove (file, fileList) {
      this.form.company_logo_url = [];
      fileList.forEach(item1 => {
        this.form.company_logo_url = item1.response.data.visit_url;
      });
    },
    beforeRemove (file, fileList) {
      if (file && file.status==="success") {
      return this.$confirm(`确定移除门店照片吗？`);
      }
    },
    submitForm (form) {
      let params = {
        company_id: this.form.company_id,
        company_name: this.form.company_name
      }
      
      this.$refs[form].validate((valid) => {
        this.form.province =this.provinceTemp?this.provinceTemp.region_name:'';
        this.form.city = this.cityTemp?this.cityTemp.region_name:'';
        this.form.area = this.areaTemp?this.areaTemp.region_name:'';
        this.form.town =this.townTemp?this.townTemp.region_name:'';
        this.form.vill =this.villTemp?this.villTemp.region_name:'';
        if (valid) {
          if(this.company_type_id!=this.form.company_type_id){
       this.$confirm('是否确认更改监管类型？一经修改，无法复原！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.changeCompany()
        }).catch(() => {  
           return false;      
        });
           
      }else{
        this.changeCompany()
      }
           
        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
      })
    },
    changeCompany(){
       axios.put('/pc/company/update', this.form).then((v) => {
              local.set('company_id', this.form.company_id);
              local.set('company_name', this.form.company_name);
              local.set('isMenu', 2);
              axios.put('/pc/user-role/refresh/menu', { last_login_company_id: this.form.company_id }).then((v1) => {
                this.menu_list = v1.data.menu_list;
                this.url = v1.data.menu_list[0].menu_list[0].menu_url;
                this.$router.replace({
                  path: this.url + '?company_id=' + this.form.company_id
                });
              })
            })
    },
    getCompany (params) {
      var that = this;
      axios.get('/pc/company/single', { company_id: String(params) }).then((v) => {
        local.set('company', v.data.company);
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    top: 0px;
    left: 30px;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #2ec68a;
    border-color: #2ec68a;
    color: #ffffff;
  }
  .list_tab {
    padding: 10px;
    background-color: #f5f7fa;
    .list_tag {
      right: -10px;
      padding: 2px 6px;
      border-radius: 10px;
      top: -10px;
    }
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/.el-table .aa .cell {
    display: flex;
    justify-content: center;
  }
}
</style>
